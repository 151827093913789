<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="pa-10 flex-column  bg-white overflow-hidden radius-20">
				<div class="bg-popup-title pa-10-20 flex-row justify-space-between items-center under-line">
					<h4>은행 출금 요청</h4>
					<v-icon
						@click="cancel"
					>mdi-close-circle</v-icon>
				</div>

				<div
					class=" overflow-y-auto ptb-10 "
					style="max-height: 480px"
				>
					<template
						v-if="is_holder"
					>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">은행</div>
							<div class="flex-2">
								<select
									v-model="item_withdrawal.bank"
									class=""
									disabled=""
									style="border: unset; color: black;"
								>
									<option value="">은행을 선택하세요</option>
									<option
										v-for="(item, b_index) in user.bank_list"
										:key="'bank_' + b_index"
										:value="item.bankId"
									>{{ item.bankName }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 계좌</div>
							<div class="flex-2">{{ item_withdrawal.account }}</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">예금주</div>
							<div class="flex-2" :class="{ 'color-red': !item_holder.realBankOwnerName }">{{ item_holder.realBankOwnerName ? item_holder.realBankOwnerName : '출금계좌를 다시 확인하세요' }}</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 금액</div>
							<div class="flex-2">{{ item_withdrawal.amount | makeComma }}원</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">입금자명</div>
							<div class="flex-2">{{ item_withdrawal.depositor }}</div>
						</div>
					</template>

					<template
						v-else
					>
						<div class="flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금가능금액</div>
							<div class="flex-2"><input class="input-box  size-px-14 font-weight-bold" :placeholder="item_merchant.balanceTotAmt | makeComma"  disabled /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">은행</div>
							<div class="flex-2">
								<select
									v-model="item_withdrawal.bank"
									class="input-box "
								>
									<option value="">은행을 선택하세요</option>
									<option
										v-for="(item, b_index) in user.bank_list"
										:key="'bank_' + b_index"
										:value="item.bankId"
									>{{ item.bankName }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 계좌</div>
							<div class="flex-2"><input v-model="item_withdrawal.account" class="input-box " placeholder="출금 계좌를 입력하세요" maxlength="50" type="number" :rules="$rules.max(item_withdrawal, 'account', 50)"/></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">예금주</div>
							<div class="flex-2"><input v-model="item_withdrawal.holder" class="input-box " placeholder="예금주를 입력하세요" maxlength="50" /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 금액</div>
							<div class="flex-2"><input v-model="item_withdrawal.amount" class="input-box " placeholder="출금 금액을 입력하세요" maxlength="10" :rules="[$rules.max(item_withdrawal, 'amount', 10)]" type="number" /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold"></div>
							<div class="flex-2 right color-blue">{{ txt_amount }}원</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">입금자명</div>
							<div class="flex-2"><input v-model="item_withdrawal.depositor" class="input-box " placeholder="입금자명을 입력하세요" maxlength="10" /></div>
						</div>
						<div
							v-if="is_holder"
							class="mt-10 flex-row justify-space-between items-center"
						>
							<div class="flex-1 font-weight-bold color-red">예금주 확인</div>
							<div class="flex-2 "><input class="input-box text-right" placeholder="입금자명을 입력하세요" maxlength="10" disabled :value="item_holder.realBankOwnerName" /></div>
						</div>
					</template>
				</div>

				<div
					class="mt-auto "
				>
					<button
						v-if="is_holder"
						@click="onPin"
						class="btn btn-primary pa-10"
						:disabled="is_to_merchant_confirm"
					><slot name="name-confirm">확인</slot></button>
					<button
						v-else
						@click="postHolder"
						class="btn btn-primary pa-10"
						:disabled="is_to_merchant_withdrawal"
					><slot name="name-confirm">출금</slot></button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "../Layout/PopupLayer";
export default {
	name: 'MerchantWithdrawal'
	, components: {PopupLayer}
	, props: ['user', 'item_merchant', 'type']
	, data: function(){
		return {

			item_withdrawal: {
				amount: ''
				, bank: ''
				, account: ''
				, holder: ''
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, depositor: ''
				,withdrawal_type: this.type
			}
			, is_holder: false
			, item_holder: {}
		}
	}
	,computed: {
		is_to_merchant_withdrawal: function () {
			let t = true
			if (this.item_withdrawal.bank && this.item_withdrawal.account && this.item_withdrawal.holder && this.item_withdrawal.amount && this.item_withdrawal.depositor) {
				if (this.item_merchant.balanceTotAmt >= Number(this.item_withdrawal.amount) + Number(this.user.virtual_fee)) {
					t = false
				}
			}

			return t
		}
		, is_to_merchant_confirm: function () {
			let t = true
			if (this.item_withdrawal.bank && this.item_withdrawal.account && this.item_withdrawal.holder && this.item_withdrawal.amount && this.item_withdrawal.depositor && this.item_holder.realBankOwnerName) {
				if (this.item_merchant.balanceTotAmt >= Number(this.item_withdrawal.amount) + Number(this.user.virtual_fee)) {
					t = false
				}
			}

			return t
		}

		, txt_amount: function(){
			let t = 0
			if(this.item_withdrawal.amount){
				t = this.$common.geKoreanNumber((this.item_withdrawal.amount + '').replaceAll(',', ''))
			}
			return t
		}
	}
	, methods: {

		postMerchantWithdrawal: async function(){
			this.$bus.$emit('on', true)
			try {
				if(!this.is_holder){
					throw 'not holder'
				}
				const result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_merchant_withdrawal
					, data: this.item_withdrawal
				})

				if (result.success) {
					this.$emit('click')
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.$bus.$off('pinCallback')
				this.is_holder = false
			}
		}
		, postHolder: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'member/postHolder'
					, data: this.item_withdrawal
				})
				if(result.success){
					this.is_holder = true
					this.item_holder = result.data
					this.item_withdrawal.holder = this.item_holder.realBankOwnerName
				}else{
					throw result.message
				}
			}catch(e){
				this.is_holder = false
				this.item_holder = {}
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
				console.log('pinCallback', call)
				this.$set(this.item_withdrawal, 'pin', call)
				this.postMerchantWithdrawal()
			})
		}
		, cancel: function(){
			if(this.is_holder){
				this.is_holder = false
			}else{
				this.$emit('cancel')
			}
		}
	}
	, created() {
	}
}
</script>